import React from "react"
import { Form } from "antd"

import NotificationsForm from "./NotificationsForm"

import "./Notifications.less"

const Notifications = () => {
  const [form] = Form.useForm()

  return (
    <div className="settings__notifications">
      <Form form={form} name="notifications">
        <NotificationsForm
          form={form}
          onSubmit={() => {}}
          onCancel={() => {}}
        />
      </Form>
    </div>
  )
}

export default Notifications
