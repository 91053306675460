import React from "react"
import { List, Button, Avatar } from "antd"
import { Link } from "react-router-dom"
import "./FeaturedProfile.less"

const data = [
  {
    payed: true,
    price: "$1/month",
    avatar:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    name: "Alice Hughes",
    followers: "1k",
    profession: "Programmer",
    username: "alice",
  },
  {
    payed: true,
    price: "$1/month",
    avatar:
      "https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png",
    name: "Alice Hughes",
    followers: "1k",
    profession: "Programmer",
    username: "alice",
  },
]
export const FeaturedProfile = () => (
  <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item
        className="FeaturedProfile__listItem"
        extra={
          <Button className="FeaturedProfile__button" type="secondary">
            {item.payed ? item.price : "Subscribed"}
          </Button>
        }
      >
        <List.Item.Meta
          avatar={
            <Avatar
              style={{ borderRadius: 8 }}
              size={64}
              shape="square"
              src={item.avatar}
            />
          }
          title={
            <>
              <Link to={`/profile/${item.username}`}>{item.name}</Link>
              <div className="profile__info">
                <Link to={`/profile/${item.profession}`}>
                  {item.profession.toUpperCase()}
                </Link>
                &#8226;
                <span>{item.followers}</span>
              </div>
            </>
          }
          //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
        />
      </List.Item>
    )}
  />
)
