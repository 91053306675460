import React from "react"
import { Card, Avatar } from "antd"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import "./Notifications.less"

dayjs.extend(relativeTime)

const Notification = ({ item }) => {
  const renderType = (type) => (
    <div className="notification__type">
      {type === "commented" && "Commented on your photo:"}
      {type === "liked" && "Liked your photo"}
      {type === "subscribed" && "Subscribed to your profile:"}
      {type === "tipped" && "Tipped your photo:"}
      {type === "promotion" && "Promotion notification here:"}
    </div>
  )

  return (
    <Card type="inner" bordered={false}>
      <Avatar className="notification__avatar" src={item.avatar} />
      <div>
        <div className="notification__name">{item.name}</div>
        <div className="notification__content">
          <div className="notification__type"> {renderType(item.type)} </div>
          {!!item.comment && `${item.comment}`}
          {!!item.amount && `$${item.amount}`}
        </div>
        <div className="notification__time"> {dayjs(item.time).from()} </div>
      </div>
      {!!item.picture && (
        <img
          src={item.picture}
          alt="notification"
          className="notification__img"
        />
      )}
    </Card>
  )
}

export default Notification
