import React from "react"
import { Drawer, Menu, Avatar } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { withRouter } from "react-router-dom"

import menu from "./menu"

import "./Sidebar.less"

const Sidebar = ({
  history,
  onClose,
  shown,
  size = "large",
  profileImage,
  fullName = "Mandi Nishtulla",
  username = "@tonctonc",
  fansCount = "70k",
  following = "1k",
}) => {
  const onClick = (to) => {
    history.push(to)
    onClose()
  }

  const title = (
    <>
      <Avatar
        size={80}
        shape="square"
        className="avatar"
        icon={<UserOutlined />}
        src={profileImage}
      />
      <div className="fullName">{fullName}</div>
      <div className="username">{username}</div>
      <div className="info">
        <div>
          <span>{fansCount}</span> Fans
        </div>
        <div>
          <span>{following}</span> Following
        </div>
      </div>
    </>
  )

  return (
    <Drawer
      title={title}
      onClose={onClose}
      visible={shown}
      className="sidebar"
      width={size === "large" ? 420 : undefined}
    >
      <Menu className="menu">
        {menu.map((item, i) =>
          item.key === "divider" ? (
            <Menu.Divider key={`${item.key}-${i}`} className="menuDivider" />
          ) : (
            <Menu.Item
              key={item.key}
              className="menuItem"
              onClick={() => onClick(item.to)}
            >
              {item.icon}
              {item.label}
            </Menu.Item>
          ),
        )}
      </Menu>
    </Drawer>
  )
}

export default withRouter(Sidebar)
