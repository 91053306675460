export default {
	name: [ { required: true, message: "Please input your name!" } ],

	email: [
		{ required: true, message: "Please input your email!" },
		{
			type: "email",
			message: "You must enter a valid email!"
		}
	],

	password: [
		{ required: true, message: "Please input your password!" },
		{
			validator: (_, value) => {
				return value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s)/)
					? Promise.resolve()
					: Promise.reject(
							"*Must Contain One Uppercase, One Lowercase, One Number and one special case Character"
						)
			}
		},
		{
			min: 8,
			message: "*At least 8 characters long"
		}
	]
}
