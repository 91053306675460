import React from "react"
import { Form, Button } from "antd"

import { LabeledInput } from "components"

import "./Profile.less"

const ProfileForm = ({ onSubmit, onCancel }) => {
  return (
    <div className="settings__profile__form">
      <div className="settings__profile__row">
        <Form.Item name="name">
          <LabeledInput label="Display Name" />
        </Form.Item>

        <Form.Item name="username">
          <LabeledInput label="username" />
        </Form.Item>
      </div>

      <Form.Item name="about">
        <LabeledInput label="About" />
      </Form.Item>

      <div className="settings__profile__row">
        <Form.Item name="location">
          <LabeledInput label="Location" />
        </Form.Item>

        <Form.Item name="website">
          <LabeledInput label="Website URL" />
        </Form.Item>
      </div>

      <div className="settings__profile__row">
        <Form.Item name="price">
          <LabeledInput label="SUBSCRIPTION PRICE" />
        </Form.Item>
      </div>

      <Button
        type="primary"
        className="settings__profile__button"
        onClick={onSubmit}
      >
        Save
      </Button>

      <Button
        type="text"
        className="settings__profile__button"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  )
}

export default ProfileForm
