export default {
  authToken: function () {
    return localStorage.getItem("JUSTBACKERS.AUTH_TOKEN")
  },
  login: function () {
    localStorage.setItem("JUSTBACKERS.AUTH_TOKEN", "1")
    localStorage.setItem("JUSTBACKERS.USER_ROLES", JSON.stringify(["user"]))
  },
  logout: function () {
    localStorage.setItem("JUSTBACKERS.AUTH_TOKEN", null)
  },
  roles: function () {
    return localStorage.getItem("JUSTBACKERS.USER_ROLES")
  },
  hasRole: function (role) {
    return this.roles().includes(role)
  },

  isLoggedIn: function () {
    return !!this.authToken()
  },
  isAdmin: function () {
    return this.isLoggedIn() && this.hasRole("admin")
  },
}
