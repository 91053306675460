import React from "react"
import { Form, Button } from "antd"

import { Switch, Checkbox } from "components"

import "./Notifications.less"

const notifications = [
  { value: "newCampainContribution", label: "New Campaign Contribution" },
  { value: "newComment", label: "New comment" },
  { value: "newLikes", label: "New Likes" },
  { value: "discounts", label: "Discounts from users I used to follow" },
  { value: "newSubscriber", label: "New Subscriber" },
  { value: "newTip", label: "New Tip" },
]

const NotificationsForm = ({ onSubmit, onCancel, form }) => {
  return (
    <div className="settings__notifications__form">
      <div className="settings__notifications__row">
        <div className="settings__notifications__section">
          Push Notifications
        </div>
        <Form.Item name="pushNotifications" noStyle>
          <Switch />
        </Form.Item>
      </div>

      <div className="settings__notifications__row">
        <div className="settings__notifications__section">
          Email Notifications
        </div>
        <Form.Item name="emailNotifications" noStyle>
          <Switch />
        </Form.Item>
      </div>

      <div className="settings__notifications__section topOffset">
        receive site notifications about
      </div>

      <div className="settings__notifications__checkboxes">
        {notifications.map(({ value, label }) => (
          <Form.Item name={value}>
            <Checkbox>{label}</Checkbox>
          </Form.Item>
        ))}
      </div>

      <Button
        type="primary"
        className="settings__notifications__button"
        onClick={onSubmit}
      >
        Save
      </Button>

      <Button
        type="text"
        className="settings__notifications__button"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  )
}

export default NotificationsForm
