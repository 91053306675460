import React from "react"

import { withPage } from "utils"
import { Title, Subtitle } from "components"

import "./ThankYou.less"

const ThankYou = () => (
  <div className="thankYou">
    <div className="thankYouTriangleContainer">
      <div className="thankYouTriangle" />
    </div>
    <Subtitle position="center">You can close this tab now</Subtitle>
    <Title position="center">Thanks! We'll be in touch soon.</Title>
  </div>
)

export default withPage(ThankYou, { noScroll: true })
