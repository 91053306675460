import React from "react"
import { Input } from "antd"
import ControlPanel from "../ControlPanel/ControlPanel"

import "./HeaderControl.less"

const { Search } = Input

const HeaderControl = ({ showSidebar }) => {
  return (
    <>
      <div className="headerControlSearch">
        <Search placeholder="Search" />
      </div>
      <ControlPanel showSidebar={showSidebar} />
    </>
  )
}

export default HeaderControl
