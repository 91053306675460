import React from "react"
import { Card, Avatar, Button, Row, Col } from "antd"
import { HeartOutlined, CommentOutlined, BookOutlined } from "@ant-design/icons"
import "./Card.less"

const { Meta } = Card

export default () => (
  <Card
    bordered={false}
    className="feed-item"
    title={
      <Meta
        className="post-card"
        avatar={
          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        }
        title="Card title"
        description="This is the description"
      />
    }
    extra={<div className="card-description">15 mins ago</div>}
    cover={
      <img
        alt="example"
        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
      />
    }
  >
    <Row type="flex">
      <Col className="flex" span={12}>
        <div className="pad-0-20">
          <HeartOutlined className="ft-30" /> 14
        </div>
        <div className="pad-0-20">
          <CommentOutlined className="ft-30" /> 14
        </div>
      </Col>
      <Col className="flex flex-end" span={12}>
        <div className="pad-0-20">
          <BookOutlined className="ft-30" />
        </div>
        <div className="pad-0-20">
          <Button size="large" type="primary">
            Buy gift
          </Button>
        </div>
      </Col>
    </Row>
  </Card>
)
