export default {
  apiKey: "AIzaSyCX6RVtTKmz48B-W2JZdqs80v8dBWj0Ekc",
  docId: "1mVKuI2B3MZTTX2r12zC02MJO0kfYGwWBQ6oUEZnCVIM",
  clientId:
    "567780929643-3g304r6v1r6hebife6a1nobkoieh2jsb.apps.googleusercontent.com",
  clientSecret: "Ry4qESksaKZ3w1IkzqReKLnx",
  scope: "https://www.googleapis.com/auth/spreadsheets",
  discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],

  type: "service_account",
  project_id: "stg-just-backers",
  private_key_id: "aa0a2735a54b3ee2b0a8ad9ca676d085691723c4",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDSIimipvnoBMda\nl1+6RL8Sw6RrDMU82HsgkOssnRIJX7awrps5rQzVViMYUcHd+EPWPoTsJhd7tCI/\nQoeSLzbDx/MCVoQj3H30jgm0g+CytIXdRD0rDdzImlHVPaXPrrsAfOhAOHuQ1mEr\ndOXs8dqoJj2lzJ1YXPFTBJP/Sw/GAFmoxa91Kti1bozeP2HqFLuXWW1YFhYYvHXz\n1dX1FXZea+0TAcgvAYg4bCgKtrNHWNP/tTHOwvY0s0ymDakqhhOqslqRdxwQnks8\n/14wum6g/E87C9G00MAjQXt/KBsywg2xl9utX3TIQ8kyf/f7+yu3AcfhRkCSJuK0\nQ4HPkNGZAgMBAAECggEADPeJt91I8kiKisG+PlmBVvrsBE/F7hgdGlMxsqTrWNkS\nBiYdiGTFiXamyhiQIjlF6mHHmbuiBa1bKFSGgX6uCPfSPEndc/RmcLcWdzq6HMen\nXmeYb2CMHXQ/FNht3nqAVKqLOcdNLLtl709n5C6/2PzUdLeYhWblh24dZIrGaZKS\n8KmMx189T7aY+JsUCTRTkuYfo7luKshGf3eDri1E2gOdjlYOP7IP3iuoZlmXlMKB\nkN3RJmB1qD6uQPf1DE+Pq6TANF232FFxVI4lR4WLUerggSUfJWRLerx+Uy5Z00IN\ne8HQmxAaK4Dpt+bRG3AeCZENf+UJVSivRNAnmEn+NwKBgQD3rVcb5q48LH6ZX3gH\nEQNJylyBIO+obaKsWqBPpKWjMG/gsLPc8kGN/FHCcLsl58f/xtmadJS12HKvWJ/A\ni+zQkADxTtmhHInjCqEnma7be5Mi3Iaaw/ZMIo+Gf4+Jre0LvccFFOZiS0bU1x2+\nSsXTBNn7RiX8p1eXPWMTkQxymwKBgQDZMdmyME3fhV3XNThDsYjoXCF8uGKlJhkr\nG+SwwYmz5BfdYB+8Qzf16mPwDQsY1buUK2SCpq8A63XO+/1eb/3b9p3MJnDGwQ/c\nbZvY54h6UD4cyQQy/oUBPxnkusQbzb3S/AldPqK1poo9LOsiabd8BE3RYMKbiNxe\n+3njhgVF2wKBgEavofzEiVWDHV6IcKkToeydZGk2DS/0mki2VKpmQx+f404tICge\nu7baHDtyEPdwfxPwnIoIW6jKbXC9yloZlSyw7JPx/QqOKowxrGyw38akLgn1BjWF\nV/XCp1OrdDvG97AyKWq53EaRVVXJTyPQE0V2TP/5tT22RLlVO8GJ1BOhAoGBAInA\nwk/vrxRbY/9As+TLTwgAlwShdX9FQKVE1bD9rza3LiOr+bTP9Ug1VxlDTIx90rDR\nLLhou4obzOZwkGw7H4lJSx/WKaUFgefOZRAXGUpqZlqfa9hj8TuvJ98YTZVM0l5k\nmBPZzBzl+bt/J8r1vfgcSrbHZeZ70Tk0ykPyFpoNAoGAJ6Q5FKrqxgCXNDK9aclW\nYo32drDqcqCo2lfcu/oShUe/8M4tfhuR3BcItQrpwauSsV31IJ7Ce6/n6+Mn6Zme\nVzqOSFOna0x2MGrZWZo4kmT4gp6RgN5yfxfD7JRDPGde23TCpFoMwG8ibozzVt7E\ndxiG9CWEYgzlYY0WZFlA1Y0=\n-----END PRIVATE KEY-----\n",
  client_email: "stg-just-backers@stg-just-backers.iam.gserviceaccount.com",
  client_id: "116040976614399532940",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/stg-just-backers%40stg-just-backers.iam.gserviceaccount.com",
}
