import React, { useState } from "react"
import { User } from "models"
import WrapperWithLogo from "./WrapperWithLogo/WrapperWithLogo"
import HeaderControl from "./HeaderControl/HeaderControl"
import SignUpButton from "./SignUpButton/SignUpButton"
import { Sidebar } from "components"

const Header = () => {
  const [shown, setShown] = useState(false)

  return !User.isLoggedIn() ? (
    <WrapperWithLogo>
      <SignUpButton />
    </WrapperWithLogo>
  ) : (
    <>
      <WrapperWithLogo>
        <HeaderControl showSidebar={setShown} />
      </WrapperWithLogo>

      <Sidebar shown={shown} onClose={() => setShown(false)} />
    </>
  )
}

export default Header
