import React from "react"
import {
  UserOutlined,
  UpSquareOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  CreditCardOutlined,
  LogoutOutlined,
} from "@ant-design/icons"

export default [
  {
    key: "profile",
    label: "My Profile",
    icon: <UserOutlined />,
    to: "/profile",
  },
  {
    key: "bookmarks",
    label: "Bookmarks",
    icon: <UpSquareOutlined />,
    to: "/bookmarks",
  },
  {
    key: "lists",
    label: "Lists",
    icon: <UnorderedListOutlined />,
    to: "/lists",
  },
  { key: "divider" },
  {
    key: "settings",
    label: "Settings",
    icon: <SettingOutlined />,
    to: "/settings",
  },
  {
    key: "cards",
    label: "Cards",
    icon: <CreditCardOutlined />,
    to: "/cards",
  },
  { key: "divider" },
  {
    key: "logout",
    label: "Logout",
    icon: <LogoutOutlined />,
    to: "/logout",
  },
]
