import React from "react"
import { Input, Button, Form } from "antd"
import { useHistory } from "react-router-dom"

import { mobiles } from "assets"
import { withPage, useLocalStorage } from "utils"
import { Title, Subtitle } from "components"

import "./ComingSoon.less"

const ComingSoon = () => {
  const [form] = Form.useForm()
  const [, setEmail] = useLocalStorage("JUSTBACKERS.COMING_SOON_EMAIL", "")
  const history = useHistory()

  const onSubmit = () => {
    form.validateFields().then((values) => {
      setEmail(values.email)
      history.push("/feedback")
    })
  }

  return (
    <div className="comingSoon">
      <div className="comingSoonTriangleContainer">
        <div className="comingSoonTriangle" />
      </div>
      <Subtitle position="center">Join the list to get Update!</Subtitle>
      <Title position="center">make money & interact with your fans</Title>
      <Form form={form}>
        <div className="comingSoonInputContainer">
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter a valid email.",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Button type="primary" onClick={onSubmit}>
            Join list
          </Button>
        </div>
      </Form>
      <div className="comingSoonMobiles">
        <img src={mobiles} alt="mobiles" />
      </div>
    </div>
  )
}

export default withPage(ComingSoon)
