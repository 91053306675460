import React from "react"

import Header from "../Header/Header"
import "./Page.less"

const Page = ({ children, headerProps, noScroll }) => {
  const { noHeader, ...headerPropsRest } = headerProps || {}
  return (
    <div
      className={`page ${noScroll ? "pageNoScroll" : ""} ${
        noHeader ? "pageNoHeader" : ""
      }`}
    >
      {!noHeader && <Header {...headerPropsRest} />}
      {children}
    </div>
  )
}

export default Page
