import React from "react"
import { Tabs } from "components"
import FeaturedProfiles from "./FeaturedProfiles"
import { Radio } from "antd"

const Discover = () => {
  console.log({ FeaturedProfiles })
  return (
    <>
      <Radio.Group defaultValue="a" buttonStyle="solid">
        <Radio.Button value="a">Hangzhou</Radio.Button>
        <Radio.Button value="b">Shanghai</Radio.Button>
        <Radio.Button value="c">Beijing</Radio.Button>
        <Radio.Button value="d">Chengdu</Radio.Button>
      </Radio.Group>
      <Tabs
        tabs={[
          {
            value: "featured-profiles",
            label: "Featured Profiles",
            children: <FeaturedProfiles />,
            to: "/discover",
          },
          {
            value: "trending",
            label: "Trending",
            children: <FeaturedProfiles />,
            to: "/discover",
          },
          {
            value: "recent",
            label: "Recent",
            children: <FeaturedProfiles />,
            to: "/discover",
          },
        ]}
      />
    </>
  )
}

export default Discover
