import React from "react"
import { Route, Redirect } from "react-router-dom"
import { User } from "models"

const ProtectedRoute = ({ children, guard, ...rest }) => {
  const middlewares = {
    auth: User.isLoggedIn(),
    admin: User.isAdmin(),
    guest: !User.isLoggedIn(),
  }

  const redirects = {
    auth: "/login",
    admin: "/",
    guest: "/",
  }
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return !guard || middlewares[guard] ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirects[guard],
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}

export default (routes) => {
  return routes.map(({ Page, ...route }, i) => (
    <ProtectedRoute key={`${i}`} {...route}>
      <Page />
    </ProtectedRoute>
  ))
}
