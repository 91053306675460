import React from "react"
import { Form, Button } from "antd"
import { TwitterOutlined, GoogleOutlined } from "@ant-design/icons"

import { LabeledInput, ConnectedButton } from "components"

import "./Account.less"

const AccountForm = ({ onSubmit, onCancel, form }) => {
  return (
    <div className="settings__account__form">
      <div className="settings__account__section">Connected Accounts</div>
      <div className="settings__account__row">
        <Form.Item name="twitter">
          <ConnectedButton
            color="#02AFF0"
            icon={<TwitterOutlined style={{ color: "#02AFF0" }} />}
            name="Twitter"
            onDisconnect={() => {
              form.setFieldsValue({ twitter: "" })
            }}
          />
        </Form.Item>

        <Form.Item name="google">
          <ConnectedButton
            color="#4385F4"
            icon={<GoogleOutlined style={{ color: "#4385F4" }} />}
            name="Google"
            onDisconnect={() => {
              form.setFieldsValue({ google: "" })
            }}
          />
        </Form.Item>
      </div>

      <div className="settings__account__section">Account Settings</div>
      <div className="settings__account__row">
        <Form.Item name="username">
          <LabeledInput label="username" />
        </Form.Item>

        <Form.Item name="email">
          <LabeledInput label="Email" />
        </Form.Item>
      </div>

      <div className="settings__account__section">Password</div>
      <div className="settings__account__row triple">
        <Form.Item name="currentPassword">
          <LabeledInput label="Current Password" />
        </Form.Item>

        <Form.Item name="newPassword">
          <LabeledInput label="Enter new password" />
        </Form.Item>

        <Form.Item name="confirmPassword">
          <LabeledInput label="Re-Enter new password" />
        </Form.Item>
      </div>

      <div className="settings__account__deactivate">
        Want to deactive your account?{" "}
        <span className="settings__account__deactivate-link">Click here</span>
      </div>

      <Button
        type="primary"
        className="settings__account__button"
        onClick={onSubmit}
      >
        Save
      </Button>

      <Button
        type="text"
        className="settings__account__button"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  )
}

export default AccountForm
