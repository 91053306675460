import React from "react"
import { Input, Avatar, Button } from "antd"
import { IdcardOutlined, VideoCameraOutlined } from "@ant-design/icons"
import "./AddPost.less"

export const AddPost = () => {
  return (
    <div className="add-post-card">
      <Input
        className="add-post-input"
        prefix={
          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        }
        placeholder="Borderless"
        bordered={false}
      />
      <div className="add-post-card__Footer">
        <div>
          <Button icon={<IdcardOutlined />}>Photo</Button>
          <Button icon={<VideoCameraOutlined />}>Video</Button>
        </div>
        <div style={{ display: "flex" }}>
          <Input prefix="$" />
          <Button style={{}} type="primary">
            Post
          </Button>
        </div>
      </div>
    </div>
  )
}
