import React from "react"
import { Row, Col } from "antd"
import { mobiles } from "assets"

import "./styles.less"

const AuthWrapper = ({ children }) => {
  return (
    <div className="authWrapper">
      <Row type="flex" style={{ height: "100%", background: "#ffffff" }}>
        <Col
          {...{
            xs: { span: 20, push: 2, order: 2 },
            sm: { span: 20, push: 2, order: 2 },
            md: { span: 16, push: 4, order: 2 },
            lg: { span: 12, push: 0, order: 1 },
            xl: { span: 12, push: 0, order: 1 },
          }}
          className="columnContainer"
          style={{ alignItems: "center" }}
        >
          <div className="backgroundTriangle" />
          <img alt="mobiles" src={mobiles} className="mobilesImage" />
        </Col>
        <Col
          {...{
            xs: { span: 20, push: 2, order: 1 },
            sm: { span: 20, push: 2, order: 1 },
            md: { span: 16, push: 4, order: 1 },
            lg: { span: 11, push: 1, order: 2 },
            xl: { span: 11, push: 1, order: 2 },
          }}
          className="columnContainer"
        >
          {children}
        </Col>
      </Row>
    </div>
  )
}

export default AuthWrapper
