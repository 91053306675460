import React from "react"
import { logo } from "assets"

import "./WrapperWithLogo.less"

const WrapperWithLogo = ({ children }) => {
  return (
    <div className="header" style={{ padding: "0 45px" }}>
      <img src={logo} alt="logo" />
      <div className="headerChildrenContent">{children}</div>
    </div>
  )
}

export default WrapperWithLogo
