import React from "react"
import { Card } from "components"
import { Row, Col } from "antd"

export default () => (
  <>
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Card />
      </Col>
      <Col span={8}>
        <Card />
      </Col>
      <Col span={8}>
        <Card />
      </Col>
    </Row>
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Card />
      </Col>
      <Col span={8}>
        <Card />
      </Col>
      <Col span={8}>
        <Card />
      </Col>
    </Row>
  </>
)
