import React from "react"

import Profile from "./Profile/Profile"
import Account from "./Account/Account"
import Notifications from "./Notifications/Notifications"

export const tabs = [
  {
    value: "profile",
    label: "Profile",
    children: <Profile />,
    to: "/settings/profile",
  },
  {
    value: "account",
    label: "Account",
    children: <Account />,
    to: "/settings/account",
  },
  {
    value: "notifications",
    label: "Notifications",
    children: <Notifications />,
    to: "/settings/notifications",
  },
]
