import React from "react"
import {
  HomeOutlined,
  CompassOutlined,
  MessageOutlined,
  BellOutlined,
  UserOutlined,
} from "@ant-design/icons"

export const controllersData = [
  { key: "Feed", icon: <HomeOutlined />, to: "/feed", navigate: true },
  {
    key: "Discover",
    icon: <CompassOutlined />,
    to: "/discover",
    navigate: true,
  },
  { key: "Chat", icon: <MessageOutlined />, to: "/settings", navigate: true },
  {
    key: "Notifications",
    icon: <BellOutlined />,
    to: "/notifications",
    navigate: true,
  },
  { key: "User", icon: <UserOutlined />, to: "/profile", navigate: false },
]
