import React from "react"

import { Title, Tabs } from "components"

import { tabs } from "./data"

const Settings = () => {
  return (
    <div>
      <Title>Settings</Title>
      <Tabs tabs={tabs} />
    </div>
  )
}

export default Settings
