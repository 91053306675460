import React from "react"
import { Form } from "antd"

import { withPage } from "utils"
import { Title, Subtitle } from "components"

import FeedbackForm from "./FeedbackForm"
import "./Feedback.less"

const Feedback = (props) => {
  const [form] = Form.useForm()

  return (
    <div className="feedback">
      <Subtitle position="center">Thanks for joining the list</Subtitle>
      <Title position="center">Your feedback would be helpful</Title>
      <Form form={form} className="form" layout="vertical">
        <FeedbackForm {...props} form={form} />
      </Form>
    </div>
  )
}

export default withPage(Feedback)
