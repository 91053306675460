import React from "react"
import { useHistory } from "react-router-dom"
import { Button } from "antd"

import "./SignUpButton.less"

const SignUpButton = () => {
  const history = useHistory()

  return (
    <Button
      className="headerSignUpButton"
      onClick={() => history.push("/signup")}
    >
      Sign Up
    </Button>
  )
}

export default SignUpButton
