import React from "react"

import All from "./All/All"
import Interactions from "./Interactions/Interactions"
import Liked from "./Liked/Liked"
import Subscribed from "./Subscribed/Subscribed"
import Tipped from "./Tipped/Tipped"
import Promotions from "./Promotions/Promotions"

export const tabs = [
  {
    value: "all",
    label: "All",
    children: ({ notifications }) => <All data={notifications} />,
    to: "/notifications/all",
  },
  {
    value: "interactions",
    label: "Interactions",
    children: ({ notifications }) => (
      <Interactions
        data={notifications.filter((item) => item.type === "commented")}
      />
    ),
    to: "/notifications/interactions",
  },
  {
    value: "liked",
    label: "Liked",
    children: ({ notifications }) => (
      <Liked data={notifications.filter((item) => item.type === "liked")} />
    ),
    to: "/notifications/liked",
  },
  {
    value: "subscribed",
    label: "Subscribed",
    children: ({ notifications }) => (
      <Subscribed
        data={notifications.filter((item) => item.type === "subscribed")}
      />
    ),
    to: "/notifications/subscribed",
  },
  {
    value: "tipped",
    label: "Tipped",
    children: ({ notifications }) => (
      <Tipped data={notifications.filter((item) => item.type === "tipped")} />
    ),
    to: "/notifications/tipped",
  },
  {
    value: "promotions",
    label: "Promotions",
    children: ({ notifications }) => (
      <Promotions
        data={notifications.filter((item) => item.type === "promotion")}
      />
    ),
    to: "/notifications/promotions",
  },
]

export const notifications = [
  {
    name: "Alice Hughes",
    type: "commented",
    comment: "abc def ghi <3",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    picture: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    time: "2020-10-02 10:30",
  },
  {
    name: "Alice Hughes",
    type: "liked",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    picture: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    time: "2020-10-02 10:30",
  },
  {
    name: "Alice Hughes",
    type: "subscribed",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    time: "2020-10-02 10:30",
    amount: "15",
  },
  {
    name: "Alice Hughes",
    type: "tipped",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    time: "2020-10-02 10:30",
    amount: "5",
  },
  {
    name: "Alice Hughes",
    type: "promotion",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    picture: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    time: "2020-10-02 10:30",
    amount: "5",
  },
]
