import React from "react"

import All from "./All/All"

export const tabs = [
  {
    value: "all",
    label: "All",
    children: <All />,
    to: "/profile/all",
  },
  {
    value: "posts",
    label: "Posts",
    children: <div />,
    to: "/profile/posts",
  },
  {
    value: "videos",
    label: "Videos",
    children: <div />,
    to: "/profile/videos",
  },
  {
    value: "archived",
    label: "Archived",
    children: <div />,
    to: "/profile/archived",
  },
]
