import React, { useEffect, useState } from "react"
import { Button, Form, Slider } from "antd"
import { useHistory } from "react-router-dom"
import { GoogleSpreadsheet } from "google-spreadsheet"

import { QuestionWithRadio } from "components"
import { useLocalStorage } from "utils"
import configs from "../configs"

import "./Feedback.less"

const formatNumber = (value = "") =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

const GOOGLE_SHEETS_CREDS = {
  private_key: configs.private_key.replace(/\\n/g, "\n"),
  client_email: configs.client_email,
}
const SPREADSHEET_ID = configs.docId
let doc = new GoogleSpreadsheet(SPREADSHEET_ID)

const FeedbackForm = ({ form }) => {
  const history = useHistory()
  const [subCount, setSubCount] = useState(5000)
  const [email] = useLocalStorage("JUSTBACKERS.COMING_SOON_EMAIL", "")
  const [sheet, setSheet] = useState(undefined)
  const questions = [
    {
      name: "currentlyUsing",
      label: "Do you currently use onlyfans?",
      message: "Please choose an option.",
      options: [
        { label: "Yes", value: "yes" },
        { label: "No", value: "no" },
      ],
    },
    {
      name: "subscribersCount",
      label: "How many subscribers do you have?",
      message: "Please choose a subscriber count.",
      options: [
        { label: "100", value: "100" },
        { label: "1K", value: "1000" },
        { label: "10K", value: "10000" },
        { label: "100K", value: "100000" },
      ],
    },
  ]

  useEffect(() => {
    const initDoc = async () => {
      doc.useServiceAccountAuth(GOOGLE_SHEETS_CREDS)
      await doc.loadInfo()
      setSheet(doc.sheetsByIndex[0])
    }

    initDoc()
  }, [])

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (sheet) {
        sheet
          .addRow([email, ...Object.values(values), subCount])
          .then(() => {
            history.push("/thankyou")
          })
          .catch(console.error)
      } else {
        console.log({ sheet: "Sheet unavailable" })
      }
    })
  }

  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {() => (
          <Form.Item
            name={questions[0].name}
            rules={[{ required: true, message: questions[0].message }]}
          >
            <QuestionWithRadio
              label={questions[0].label}
              options={questions[0].options}
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {() =>
          form.getFieldValue("currentlyUsing") === "yes" && (
            <>
              {/* <Form.Item
               name={questions[1].name}
               rules={[{ required: true, message: questions[1].message }]}
               label={questions[1].label}
             >
               <QuestionWithRadio
                 label={questions[1].label}
                 options={questions[1].options}
               /> */}
              <div className="feedbackItemLabel">{questions[1].label}</div>
              <Slider
                step={100}
                min={0}
                max={10000}
                tipFormatter={(value) =>
                  value === 10000 ? "10,000+" : formatNumber(value)
                }
                value={typeof subCount === "number" ? subCount : 0}
                onChange={setSubCount}
              />
              <div className="feedbackCount">
                Subscribers count:{" "}
                {subCount === 10000 ? "10,000+" : formatNumber(subCount)}
              </div>
              {/* </Form.Item> */}
            </>
          )
        }
      </Form.Item>
      <Button type="primary" onClick={onSubmit}>
        Submit
      </Button>
    </>
  )
}

export default FeedbackForm
