import React from "react"
import { CloseOutlined } from "@ant-design/icons"

import "./ConnectedButton.less"

const ConnectedButton = ({
  value,
  color,
  icon,
  name,
  onConnect,
  onDisconnect,
}) => {
  const isConnected = !!value

  return (
    <div
      className={`connected-button ${isConnected ? "is-connected" : ""}`}
      style={{ borderColor: color }}
    >
      {icon}

      {isConnected && (
        <div className="connected-button__connected-content">
          Connected
          <div className="connected-button__username">{value}</div>
        </div>
      )}

      {!isConnected && (
        <div
          className="connected-button__disconnected-content"
          style={{ color }}
        >
          Connect with {name}
        </div>
      )}

      {isConnected && (
        <div className="connected-button__close-icon" onClick={onDisconnect}>
          <CloseOutlined />
        </div>
      )}
    </div>
  )
}

export default ConnectedButton
