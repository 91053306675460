import React from "react"

import Notification from "../Notification"
import "../Notifications.less"

const Tipped = ({ data }) => {
  return (
    <div className="notifications">
      {data.map((item, i) => (
        <Notification key={i} item={item} />
      ))}
    </div>
  )
}

export default Tipped
