import React from "react"

import { Page } from "components"

export default function withPage(Component, pageProps) {
  const Comp = (props) => (
    <Page {...pageProps}>
      <Component {...props} />
    </Page>
  )

  return Comp
}
