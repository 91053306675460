export const SignInContents = {
  title: "Welcome Back!",
  instruction: "Login to get started",
  submitButtonText: "Login",
  otherButtonText: "Login",
  maybeHaveAccountText: "Don't have an account yet? ",
  link: "Register now",
  to: "/signup",
}

export const SignUpContents = {
  title: "Get Started",
  instruction: "Sign up to make money and interact with your fans!",
  submitButtonText: "Register",
  otherButtonText: "Signup",
  maybeHaveAccountText: "Already have an account? ",
  link: "Login now",
  to: "/login",
}
