import React from "react"

import { withPage } from "utils"
import { Title } from "components"

import "./NotFound.less"

const NotFound = () => (
  <div className="notFound">
    <div className="notFoundTriangleContainer">
      <div className="notFoundTriangle" />
    </div>
    <Title position="center">OOPS! Page not found.</Title>
  </div>
)

export default withPage(NotFound, { noScroll: true })
