import React from "react"
import { Radio } from "antd"

import "./QuestionWithRadio.less"

const QuestionWithRadio = ({ label, options, value, onChange }) => (
  <div className="questionWithRadio">
    {label}
    <br />
    <Radio.Group onChange={onChange} value={value} options={options} />
  </div>
)

export default QuestionWithRadio
