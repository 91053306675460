import React from "react"
import { Tabs } from "antd"
import { withRouter } from "react-router-dom"
import get from "lodash/get"

import "./Tabs.less"

const TabsComponent = ({
  history,
  tabs = [],
  onChange,
  defaultActiveKey,
  noNav = false,
  tabProps,
  ...props
}) => {
  let defaultKey = tabs[0] ? tabs[0].value : defaultActiveKey
  const pathname = get(history, "location.pathname")
  const tab = tabs.find((item) => item.to === pathname)
  if (pathname && tab) defaultKey = tab.value

  const handleOnChange = (key) => {
    onChange && onChange(key)
    if (!noNav) {
      const { to } = tabs.find((item) => item.value === key) || {}
      if (to) history.push(to)
    }
  }

  return (
    <Tabs
      className="tabs"
      defaultActiveKey={defaultActiveKey || defaultKey}
      activeKey={defaultKey}
      onChange={handleOnChange}
      {...props}
    >
      {tabs.map(({ value, label, children }) => (
        <Tabs.TabPane tab={label} key={value}>
          {tabProps ? children(tabProps) : children}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}
export default withRouter(TabsComponent)
