import React, { useState } from "react"
import { Upload, Button } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import { Camera, Tabs } from "components"
import { getBase64 } from "utils"

import { tabs } from "./data"

import "./Profile.less"

const Profile = ({
  type = "not",
  price = "5",
  name = "Mandi Nishtulles",
  isVerified = true,
  role = "youtuber",
  fans = "1.1K",
  following = "310",
  desc = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
  cover = "https://p.bigstockphoto.com/GeFvQkBbSLaMdpKXF1Zv_bigstock-Aerial-View-Of-Blue-Lakes-And--227291596.jpg",
}) => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const history = useHistory()

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true)
      return
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false)
        setImageUrl(imageUrl)
      })
    }
  }

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    className: "cover-uploader",
    onChange: handleChange,
  }

  return (
    <div className="profile">
      <div className="profile__cover">
        <img src={cover} alt="cover" />
        <Upload {...props}>
          <Camera />
        </Upload>
      </div>

      <div className="profile__content">
        <div className="profile__content__image">
          <img
            src="https://instagram.ftia12-1.fna.fbcdn.net/v/t51.2885-19/s320x320/53305637_581996465629217_3675278956287754240_n.jpg?_nc_ht=instagram.ftia12-1.fna.fbcdn.net&_nc_ohc=CiV5qveSNNEAX9DCd1v&oh=2b8fd2cb9bdff2ba775a08456f57e064&oe=5F8C9924"
            alt="avatar"
            style={{ width: "100%" }}
          />
          <Upload {...props}>
            <Camera />
          </Upload>
        </div>

        <div className="profile__content__name">
          {name} {isVerified && <CheckCircleOutlined />}
        </div>

        <div className="profile__content__role">{role}</div>

        <div className="profile__content__followers">
          <b>{fans}</b> fans{"  "}|{"  "}
          <b>{following}</b> following
        </div>

        <div className="profile__content__desc">{desc}</div>

        <Button
          type="primary"
          size="large"
          onClick={() => {
            type === "own" && history.push("/settings/profile")
          }}
          className={`profile__content__button ${
            type === "own" ? "" : "subscribe"
          }`}
        >
          {type === "own" && "Edit Profile"}
          {type !== "own" && (
            <>
              Subscribe
              <br />
              <span className="profile__content__price">${price}/month</span>
            </>
          )}
        </Button>

        <Tabs tabs={tabs} centered />
      </div>
    </div>
  )
}

export default Profile
