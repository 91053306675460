import React, { useState } from "react"
import { Upload, Form } from "antd"
import ImgCrop from "antd-img-crop"
import { Camera } from "components"
import { getBase64 } from "utils"

import ProfileForm from "./ProfileForm"

import "./Profile.less"

const Profile = () => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const [form] = Form.useForm()

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true)
      return
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false)
        setImageUrl(imageUrl)
      })
    }
  }

  return (
    <div className="settings__profile">
      <ImgCrop rotate>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          // beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl && (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          )}
          <Camera hidden={imageUrl} loading={loading} />
        </Upload>
      </ImgCrop>

      <Form form={form} name="profile">
        <ProfileForm form={form} onSubmit={() => {}} onCancel={() => {}} />
      </Form>
    </div>
  )
}

export default Profile
