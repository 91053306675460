import React from "react"
import { Form, Input, Button } from "antd"
import { Link, useHistory } from "react-router-dom"
import {
  TwitterOutlined,
  GoogleOutlined,
  EyeFilled,
  EyeInvisibleFilled,
} from "@ant-design/icons"
import { LabeledInput } from "components"
import { SignInContents, SignUpContents } from "./contents"
import rules from "./validationRules"
import "./styles.less"
import get from "lodash/get"

const AuthForm = () => {
  const [form] = Form.useForm()
  const history = useHistory()

  const pathname = get(history, "location.pathname")

  const handleSubmit = (e) => {
    // e.preventDefault()
    form.validateFields().then((values) => {})
  }

  const {
    title,
    instruction,
    submitButtonText,
    otherButtonText,
    maybeHaveAccountText,
    link,
    to,
  } = pathname === "/login" ? SignInContents : SignUpContents

  return (
    <div className="formContainer">
      <div className="authTitle">{title}</div>
      <div className="instruction">{instruction}</div>
      <Form
        name={`${pathname}Form`}
        size="large"
        className="authForm"
        form={form}
      >
        {pathname !== "/login" && (
          <Form.Item name="name" rules={rules.name}>
            <LabeledInput
              label="Name "
              style={{ textTransform: "capitalize" }}
            />
          </Form.Item>
        )}
        <Form.Item name="email" rules={rules.email}>
          <LabeledInput label="E-mail " />
        </Form.Item>

        <Form.Item name="password" rules={rules.password}>
          <Input.Password
            placeholder="Password"
            iconRender={(visible) =>
              visible ? <EyeFilled /> : <EyeInvisibleFilled />
            }
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            block
            htmlType="submit"
            className="submitButton"
            onClick={handleSubmit}
          >
            {submitButtonText}
          </Button>
        </Form.Item>

        {pathname === "/login" && (
          <div
            className="lineContainer"
            style={{ marginTop: "-15px", justifyContent: "flex-end" }}
          >
            <Link to="/login" className="authText link">
              {/* Change destination */}
              Forgot your password?
            </Link>
          </div>
        )}

        <div className="divider">
          ------------------------ Or ------------------------
        </div>

        <Form.Item>
          <Button
            block
            className="helperButton twitterButton"
            icon={<TwitterOutlined />}
          >
            {otherButtonText} with Twitter
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            block
            className="helperButton googleButton"
            icon={<GoogleOutlined />}
          >
            {otherButtonText} with Google
          </Button>
        </Form.Item>

        <div className="authText lineContainer">
          {maybeHaveAccountText}
          <Link to={to} className="link">
            {/* Change destination */}
            {link}
          </Link>
        </div>
      </Form>
    </div>
  )
}

export default AuthForm
