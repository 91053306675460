import React, { useState } from "react"
import { Avatar, Dropdown, Menu } from "antd"
import { useHistory } from "react-router-dom"
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons"
import get from "lodash/get"
import clsx from "clsx"

import { controllersData } from "./controllersData"

import "./ControlPanel.less"

const ControlPanel = ({ showSidebar }) => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)

  const menu = (
    <Menu>
      {controllersData.map(({ key, icon, to, navigate }) => (
        <Menu.Item
          key={key}
          icon={!navigate ? <Avatar>{icon}</Avatar> : icon}
          onClick={() => (navigate ? history.push(to) : showSidebar(true))}
          className={clsx({
            headerControlPanelMenuItem: true,
            headerControlPanelSelectedMenuItem:
              navigate && to === get(history, "location.pathname"),
          })}
        >
          {key}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <>
      <div className="headerControlPanel">
        {controllersData.map(({ key, icon, to, navigate }) => (
          <div
            key={key}
            className={clsx({
              controller: true,
              selectedController:
                navigate && to === get(history, "location.pathname"),
            })}
            onClick={() => (navigate ? history.push(to) : showSidebar(true))}
          >
            {!navigate ? <Avatar>{icon}</Avatar> : icon}
          </div>
        ))}
      </div>
      <div className="headerControlPanelMenuButton">
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          arrow
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </Dropdown>
      </div>
    </>
  )
}

export default ControlPanel
