import React from "react"
import { Layout } from "antd"
import { Card } from "components"

const { Content } = Layout

const All = () => {
  return (
    <Content>
      <Card />
      <Card />
      <Card />
      <Card />
      <Card />
      <Card />
    </Content>
  )
}

export default All
