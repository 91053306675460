import ComingSoon from "./ComingSoon/ComingSoon"
import Login from "./Login/Login"
import Feedback from "./Feedback/Feedback"
import ThankYou from "./ThankYou/ThankYou"
import Profile from "./Profile/Profile"
import Signup from "./Signup/Signup"
import Feed from "./Feed/Feed"
import Discover from "./Discover/Discover"
import Notifications from "./Notifications/Notifications"
import Settings from "./Settings/Settings"
import NotFound from "./NotFound/NotFound"

export default [
  { path: "/", exact: true, Page: ComingSoon },
  { path: "/login", guard: "guest", Page: Login },
  { path: "/feedback", Page: Feedback },
  { path: "/thankyou", Page: ThankYou },
  { path: "/profile", Page: Profile },
  { path: "/signup", guard: "guest", Page: Signup },
  {
    path: "/feed",
    //  guard: "auth",
    Page: Feed,
  },
  { path: "/discover", guard: "auth", Page: Discover },
  {
    path: "/notifications",
    // guard: "auth",
    Page: Notifications,
  },
  {
    path: "/settings",
    // guard: "auth",
    Page: Settings,
  },
  { Page: NotFound },
]
