import React from "react"
import AuthWrapper from "./components/AuthWrapper"
import AuthForm from "./components/AuthForm"

const Auth = ({ type }) => {
	return (
		<AuthWrapper>
			<AuthForm type={type} />
		</AuthWrapper>
	)
}

export default Auth
