import React from "react"

import { Title, Tabs } from "components"

import { tabs, notifications } from "./data"

const Notifications = () => {
  return (
    <div>
      <Title>Notifications</Title>
      <Tabs tabs={tabs} tabProps={{ notifications }} />
    </div>
  )
}

export default Notifications
