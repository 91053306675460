import React from "react"
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons"

import "./Camera.less"

const Camera = ({ hidden, loading }) => {
  return (
    <div className={`camera ${hidden ? "hidden" : ""}`}>
      {loading ? <LoadingOutlined /> : <CameraOutlined />}
    </div>
  )
}

export default Camera
