import React from "react"
import { Card } from "components"
import { Row, Col, Layout } from "antd"
import { AddPost, FeaturedProfile } from "../components"

const { Content } = Layout

const Feed = () => {
  return (
    <Content style={{ padding: "0 200px" }}>
      <Row>
        <Col xs={22} sm={20} md={18} lg={16} xl={12}>
          <AddPost />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
        </Col>
        <Col xs={2} sm={4} md={6} lg={8} xl={10} push={2}>
          <div
            style={{
              fontSize: 26,
              fontWeight: "bold",
            }}
          >
            Featured profile
          </div>
          <FeaturedProfile />
        </Col>
      </Row>
    </Content>
  )
}

export default Feed
