import React from "react"
import { Form } from "antd"

import AccountForm from "./AccountForm"

import "./Account.less"

const Account = () => {
  const [form] = Form.useForm()

  return (
    <div className="settings__account">
      <Form form={form} name="account" initialValues={{ twitter: "zotiMandi" }}>
        <AccountForm form={form} onSubmit={() => {}} onCancel={() => {}} />
      </Form>
    </div>
  )
}

export default Account
