import React from "react"

import Login from "jback-login"
import { withPage } from "utils"

const LoginRoute = () => {
  return <Login />
}

export default withPage(LoginRoute)
