import React from "react"
import clsx from "clsx"
import { Input } from "antd"

import "./LabeledInput.less"

const LabeledInput = ({ label, ...params }) => (
  <div
    className={clsx({
      hasAddonBefore: params.addonBefore,
      hasValue: params.value,
    })}
  >
    {<span className="label">{label}</span>}

    <Input {...params} autoComplete="off" />
  </div>
)

export default LabeledInput
